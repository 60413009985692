import { cloudAccountFetcher, vmWareOverlordsFetcher } from "./services";
import { createSelector } from "reselect";

export const getSelectedCloudAccount = createSelector(
  cloudAccountFetcher.selector,
  (fetcherSelector) => fetcherSelector.result
);

export const getSelectedJSONFileList = createSelector(
  (state) => state.forms?.cloudAccounts?.data?.jsonFile,
  (file) => {
    if (!file) return null;
    return [
      {
        ...file,
        uid: file.uid || -1,
        status: file.status || "done",
      },
    ];
  }
);

export const getSystemPrivateGatewayUid = createSelector(
  vmWareOverlordsFetcher.selector,
  ({ result }) =>
    result?.find((overlord) => overlord.spec.isSystem)?.metadata?.uid || ""
);
