import store from "services/store";

import Validator from "services/validator";
import { Missing, isKubernetesName, MaxLength } from "services/validator/rules";
import createActions from "modules/form/actions";

import {
  cloudAccountFetcher,
  cloudAccountFormModal,
} from "state/cloudaccounts/services";
import { createAccount } from "state/cloudaccounts/actions/create";

const validator = new Validator();
validator.addRule(["name", "tenantId", "clientId", "clientSecret"], Missing());
validator.addRule(["name"], isKubernetesName());
validator.addRule(["name"], MaxLength(32));

async function submit(data) {
  const payload = {
    metadata: {
      name: data.name,
    },
    spec: {
      tenantId: data.tenantId.trim(),
      clientId: data.clientId.trim(),
      clientSecret: data.clientSecret.trim(),
      tenantName: data.tenantName?.trim(),
    },
  };

  return store.dispatch(createAccount("azure", payload));
}

async function init() {
  let data;
  if (cloudAccountFormModal.data.uid) {
    data = await store.dispatch(cloudAccountFetcher.fetch());
    store.dispatch({
      type: "SET_ACCOUNT_AS_VALID",
    });
  }

  return Promise.resolve({
    name: data?.metadata.name || "",
    tenantId: data?.spec.tenantId || "",
    clientId: data?.spec.clientId || "",
    clientSecret: data?.spec?.clientSecret || "",
    tenantName: data?.spec?.tenantName || "",
  });
}

export const azureAccountFormActions = createActions({
  submit,
  validator,
  init,
});
