import { getCurrentStep } from "./selectors";
import createFormActions from "modules/form/actions";

export class WizardActions {
  constructor(actions = {}) {
    if (actions instanceof WizardActions) {
      return actions;
    }

    const {
      getDescription = () => " ",
      formActions = createFormActions(),
      fieldsToValidate = {},
      onStepChange = () => {},
      steps = [],
    } = actions;

    Object.assign(this, {
      getDescription,
      formActions,
      fieldsToValidate,
      onStepChange,
      steps,
    });
  }

  initialize(module) {
    return (dispatch) => {
      dispatch({
        type: "WIZARD__INITIALIZE",
        module,
        steps: this.steps,
      });
    };
  }

  _getFieldsToValidate(step) {
    let fieldsToValidate = this.fieldsToValidate;
    if (typeof this.fieldsToValidate === "function") {
      fieldsToValidate = this.fieldsToValidate();
    }

    return fieldsToValidate[step] || [];
  }

  setDescription(module) {
    return (dispatch, getState) => {
      const currentStep = getCurrentStep(module)(getState());

      dispatch({
        type: "WIZARD__SET_STEP_DESCRIPTION",
        module,
        step: currentStep,
        description: this.getDescription(this.steps[currentStep]),
      });
    };
  }

  validateCurrentStep(module) {
    return (dispatch, getState) => {
      const currentStep = getCurrentStep(module)(getState());
      let fields = this._getFieldsToValidate(currentStep);
      return dispatch(
        this.formActions.validateField({
          name: fields,
          module,
        })
      );
    };
  }

  nextStep(module) {
    return async (dispatch, getState) => {
      const currentStep = getCurrentStep(module)(getState());
      let errors = await dispatch(this.validateCurrentStep(module));

      if (currentStep !== getCurrentStep(module)(getState())) {
        errors = await dispatch(this.validateCurrentStep(module));
      }

      if (errors.length) {
        return;
      }

      dispatch(this.setDescription(module));
      dispatch({ type: "WIZARD__NEXT_STEP", module });
      this.onStepChange(this.steps[getCurrentStep(module)(getState())]);
    };
  }

  prevStep(module) {
    return async (dispatch) => {
      dispatch(this.setDescription(module));
      dispatch({ type: "WIZARD__PREV_STEP", module });
      dispatch(this.formActions.clearErrors({ module }));
    };
  }

  selectStep(module, nextStep) {
    return (dispatch, getState) => {
      // TODO find a better way to cache selectors
      const currentStep = getCurrentStep(module)(getState());
      if (nextStep > currentStep) return;

      dispatch(this.setDescription(module));
      dispatch(this.formActions.clearErrors({ module }));
      dispatch({
        type: "WIZARD__SELECT_STEP",
        module,
        step: nextStep,
      });
    };
  }

  updateSteps(module, steps) {
    return (dispatch) => {
      this.steps = steps;

      dispatch({
        type: "WIZARD__UPDATE_STEPS",
        module,
        steps,
      });
    };
  }
}
